import React from 'react';
import { Typography } from '@material-ui/core';

function BMIResult({ result, currentBmi }) {
    return (
        <div>
            <Typography variant="h6">
                {result} {currentBmi !== 0 && <strong>{currentBmi}</strong>}
            </Typography>
        </div>
    );
}

export default BMIResult;
