import React from 'react';
import { Button } from "@material-ui/core";
import { Link } from "react-router-dom";

function SaveOfflineButton() {
    return (
        <Button 
            color="primary" 
            variant="contained" 
            component={Link} 
            to="/saveoffline"
            fullWidth
        >
            Save calculator for offline use
        </Button>
    );
}

export default SaveOfflineButton;
