import React from "react";
import './App.css';
import {makeStyles} from '@material-ui/core/styles';
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
    Container,
    Grid,
    Paper,
    Typography,
} from "@material-ui/core";
import StepAppBar from "./components/StepAppBar";
import StepBetCalculator from "./components/StepBetCalculator";
import FitnessSales from "./components/FitnessSales";
import SaveOfflineButton from "./components/SaveOfflineButton";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        margin: "8px"
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
}));

function App() {
    const classes = useStyles();
    const headerImage = "./assets/step_image.jpeg";

    return (
        <div className="App">
            <StepAppBar/>
            <Container maxWidth="md">
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Paper className={classes.paper}>
                            <img src={headerImage} alt="Step" style={{ width: '100%', height: 'auto', maxHeight: '250px', objectFit: 'cover' }} />
                            <Typography variant="caption">
                                Photo by <a href="https://unsplash.com/@bruno_nascimento?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Bruno Nascimento</a> on <a href="https://unsplash.com/s/photos/step?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a>
                            </Typography>
                        </Paper>
                    </Grid>
                    
                    <StepBetCalculator />
                    
                    <Grid item xs={12}>
                        <FitnessSales />
                    </Grid>

                    <Grid item xs={12}>
                        <SaveOfflineButton />
                    </Grid>
                </Grid>
            </Container>
            
            <ToastContainer
                position="bottom-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </div>
    );
}

export default App;
