import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Container, Grid, Paper, Typography, Button } from "@material-ui/core";
import { ToastContainer, toast } from "react-toastify";
import '../App.css';
import StepAppBar from "../components/StepAppBar";
import BMIForm from "../components/BMIForm";
import BMIResult from "../components/BMIResult";
import FitnessSales from "../components/FitnessSales";
import SaveOfflineButton from "../components/SaveOfflineButton";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        margin: "8px"
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    buttonGroup: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        marginTop: theme.spacing(2),
    },
}));

export default function BMICalculator() {
    const classes = useStyles();
    const [height, setHeight] = useState(0);
    const [inches, setInches] = useState(0);
    const [weight, setWeight] = useState(0);
    const [currentBmi, setCurrentBmi] = useState(0.0);
    const [result, setResult] = useState("");
    const [metric, setMetric] = useState("standard");

    const headerImage = "../assets/bmi.webp";

    useEffect(() => {
        loadBMIData();
    }, []);

    const calculateBMI = () => {
        let heightToUse = metric === 'standard' ? (height * 12) + inches : height;
        let bmi = 0;

        if (heightToUse === "" || isNaN(heightToUse) || weight === "" || isNaN(weight)) {
            setResult("Please provide valid height and weight!");
            return;
        }

        if (metric === "standard") {
            bmi = ((weight / (heightToUse * heightToUse)) * 703).toFixed(2);
        } else {
            bmi = (weight / ((heightToUse / 100) * (heightToUse / 100))).toFixed(2);
        }

        setCurrentBmi(bmi);

        if (bmi < 18.6) setResult("Under Weight:");
        else if (bmi >= 18.6 && bmi < 24.9) setResult("Normal:");
        else setResult("Over Weight:");
    }

    const saveBMIData = () => {
        const bmiData = {
            height,
            inches,
            weight,
            metric,
            currentBmi,
            result
        };
        localStorage.setItem('bmiData', JSON.stringify(bmiData));
        toast.success("BMI data saved successfully!");
    }

    const loadBMIData = () => {
        const savedData = localStorage.getItem('bmiData');
        if (savedData) {
            const parsedData = JSON.parse(savedData);
            setHeight(parsedData.height);
            setInches(parsedData.inches);
            setWeight(parsedData.weight);
            setMetric(parsedData.metric);
            setCurrentBmi(parsedData.currentBmi);
            setResult(parsedData.result);
            toast.info("Loaded saved BMI data");
        }
    }

    const clearBMIData = () => {
        localStorage.removeItem('bmiData');
        setHeight(0);
        setInches(0);
        setWeight(0);
        setMetric('standard');
        setCurrentBmi(0);
        setResult('');
        toast.info("BMI data cleared");
    }

    return (
        <div className="App">
            <StepAppBar />
            <Container maxWidth="md">
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Paper className={classes.paper}>
                            <img src={headerImage} alt="BMI" style={{ width: '100%', height: 'auto', maxHeight: '300px', objectFit: 'cover' }} />
                            <Typography variant="caption">
                                Photo by <a href="https://unsplash.com/@neonbrand?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Kenny Eliason</a> on <a href="https://unsplash.com/s/photos/bmi?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a>
                            </Typography>
                        </Paper>
                    </Grid>
                    <Grid item xs={12}>
                        <Paper className={classes.paper}>
                            <Typography variant="h4" gutterBottom>BMI Calculator</Typography>
                            <BMIForm 
                                height={height}
                                setHeight={setHeight}
                                inches={inches}
                                setInches={setInches}
                                weight={weight}
                                setWeight={setWeight}
                                metric={metric}
                                setMetric={setMetric}
                                calculateBMI={calculateBMI}
                            />
                            <BMIResult result={result} currentBmi={currentBmi} />
                            <div className={classes.buttonGroup}>
                                <Button onClick={saveBMIData} variant="contained" color="primary">
                                    Save BMI Data
                                </Button>
                                <Button onClick={clearBMIData} variant="contained" color="secondary">
                                    Clear BMI Data
                                </Button>
                            </div>
                        </Paper>
                    </Grid>
                    <Grid item xs={12}>
                        <FitnessSales />
                    </Grid>
                    <Grid item xs={12}>
                        <SaveOfflineButton />
                    </Grid>
                </Grid>
            </Container>
            <ToastContainer
                position="bottom-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </div>
    );
}
