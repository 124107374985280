import React, { useState, useEffect } from 'react';
import {
    TextField,
    Button,
    FormControlLabel,
    Checkbox,
    Grid,
    Paper,
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails,
} from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { toast } from 'react-toastify';

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    form: {
        width: '100%',
        marginTop: theme.spacing(1),
    },
}));

function StepBetCalculator() {
    const classes = useStyles();
    const [member, setMember] = useState(false);
    const [totalPot, setTotalPot] = useState(0);
    const [eligible, setEligible] = useState(0);
    const [amountBet, setAmountBet] = useState(0);
    const [totalTake, setTotalTake] = useState("");
    const [myTake, setMyTake] = useState("");

    useEffect(() => {
        loadData();
    }, []);

    const loadData = () => {
        if (localStorage.getItem('totalPot') !== null) {
            setTotalPot(parseInt(localStorage.getItem('totalPot')));
        }
        if (localStorage.getItem('eligible') !== null) {
            setEligible(parseInt(localStorage.getItem('eligible')));
        }
        if (localStorage.getItem('amountBet') !== null) {
            setAmountBet(parseInt(localStorage.getItem('amountBet')));
        }
        if (localStorage.getItem('member') !== null) {
            setMember(localStorage.getItem('member') === 'true');
        }
    }

    const handleChange = (event) => {
        setMember(event.target.checked);
    };

    const saveData = () => {
        localStorage.setItem('totalPot', totalPot.toString());
        localStorage.setItem('eligible', eligible.toString());
        localStorage.setItem('amountBet', amountBet.toString());
        localStorage.setItem('member', member.toString());
        toast.success("Data saved successfully!");
    }

    const deleteData = () => {
        localStorage.removeItem('totalPot');
        localStorage.removeItem('eligible');
        localStorage.removeItem('amountBet');
        localStorage.removeItem('member');
        setMember(false);
        setTotalPot(0);
        setEligible(0);
        setAmountBet(0);
        toast.info("Data deleted successfully!");
    }

    const calculate = () => {
        if (totalPot === 0 || eligible === 0 || amountBet === 0 || !totalPot || !eligible || !amountBet) {
            toast.error("Please enter values greater than 0");
            return;
        }
        if (totalPot < eligible) {
            toast.error("The total pot must be greater than the number of eligible members.");
            return;
        }
        if (totalPot < amountBet) {
            toast.error("The total pot must be greater than the amount that you bet.");
            return;
        }

        let nonMemberPercentage = 0.15;
        let totalProfit = totalPot / eligible;

        if (!member) {
            let percentageTaken = totalPot * nonMemberPercentage;
            totalProfit = (totalPot - percentageTaken) / eligible;
        }

        let yourProfit = totalProfit - amountBet;
        if (yourProfit < 0) {
            totalProfit = amountBet + yourProfit;
            yourProfit = 0;
        }

        if (member) {
            setTotalTake(`Your total take home is $${totalProfit.toFixed(2)}`);
        } else {
            setTotalTake(`Your total take home after Step Bets 15% cut is $${totalProfit.toFixed(2)}`);
        }

        setMyTake(`Your total profit is $${yourProfit.toFixed(2)}`);
    }

    return (
        <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
                <Paper className={classes.paper}>
                    <form className={classes.form} noValidate autoComplete="off">
                        <TextField 
                            id="totalPot" 
                            type="number" 
                            value={totalPot} 
                            label="Total Pot" 
                            onChange={(e) => setTotalPot(parseInt(e.target.value))} 
                            variant="outlined" 
                            fullWidth
                            margin="normal"
                        />
                        <TextField 
                            id="eligible" 
                            type="number" 
                            value={eligible} 
                            label="Eligible Members" 
                            onChange={(e) => setEligible(parseInt(e.target.value))} 
                            variant="outlined" 
                            fullWidth
                            margin="normal"
                        />
                        <TextField 
                            id="amountBet" 
                            type="number" 
                            value={amountBet} 
                            label="Amount Bet" 
                            onChange={(e) => setAmountBet(parseInt(e.target.value))} 
                            variant="outlined" 
                            fullWidth
                            margin="normal"
                        />
                        <FormControlLabel
                            control={<Checkbox checked={member} onChange={handleChange} name="member" />}
                            label="Member Only Game?"
                        />
                    </form>
                </Paper>
            </Grid>
            
            <Grid item xs={12} md={6}>
                <Paper className={classes.paper}>
                    <Button onClick={calculate} variant="contained" color="primary" fullWidth>
                        Calculate
                    </Button>
                    <Typography variant="h6">{totalTake}</Typography>
                    <Typography variant="h6">{myTake}</Typography>
                </Paper>
            </Grid>
            
            <Grid item xs={12}>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMore />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                    >
                        <Typography variant="h6">More Info on Saving Data</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography variant="body1">
                            Data is not saved by default. Consider using the 'Save Data' button below so you don't have
                            to keep entering all of the data each time.
                            This only saves it to the device you are using. You can use the 'Delete Data' button to
                            delete the data.
                            If you would like to option to save data across devices, please fill out feedback form below
                            and we'll consider adding this feature with enough support.
                        </Typography>
                    </AccordionDetails>
                </Accordion>
            </Grid>
            
            <Grid item xs={12}>
                <Paper className={classes.paper}>
                    <Button onClick={saveData} variant="contained" color="primary" fullWidth style={{marginBottom: '10px'}}>
                        Save Data
                    </Button>
                    <Button onClick={deleteData} variant="contained" color="secondary" fullWidth>
                        Delete Data
                    </Button>
                </Paper>
            </Grid>
        </Grid>
    );
}

export default StepBetCalculator;
