import React from 'react';
import {
    Card,
    CardContent,
    Typography,
    List,
    ListItem,
    ListItemText,
} from "@material-ui/core";

function FitnessSales() {
    return (
        <Card>
            <CardContent>
                <Typography variant="h5" gutterBottom>Fitness Sales</Typography>
                <List>
                    <ListItem>
                        <ListItemText 
                            primary={
                                <a href="https://amzn.to/3Vxu2ye" target="_blank" rel="noopener noreferrer">
                                    Get the best smart scale measuring BMI, Body Composition and more!
                                </a>
                            }
                            secondary="Comes with an app for tracking your weight"
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemText 
                            primary={
                                <a href="https://amzn.to/4bONw7h" target="_blank" rel="noopener noreferrer">
                                    Get an apple watch to track your steps. It also has features such as crash detection and heart rate monitor.
                                </a>
                            }
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemText 
                            primary={
                                <a href="https://amzn.to/3x9WpsL" target="_blank" rel="noopener noreferrer">
                                    Get this Fitbit to track your steps. It also has features such as stress management, sleep detection, and heart rate monitor.
                                </a>
                            }
                        />
                    </ListItem>
                </List>
            </CardContent>
        </Card>
    );
}

export default FitnessSales;
