import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { TextField, FormControl, RadioGroup, FormControlLabel, Radio, Button } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
        },
        flexGrow: 1,
        margin: "8px"
    },
    stepCol: {
        margin: "8px",
        '& > *': {
            margin: theme.spacing(1),
        },
    },
}));

function BMIForm({ height, setHeight, inches, setInches, weight, setWeight, metric, setMetric, calculateBMI }) {
    const classes = useStyles();

    const handleHeightChange = (e) => {
        setHeight(parseInt(e.target.value));
    }

    const handleInchesChange = (e) => {
        setInches(parseInt(e.target.value));
    }

    const handleWeightChange = (e) => {
        setWeight(parseInt(e.target.value));
    }

    const handleMetricChange = (e) => {
        setMetric(e.target.value);
    }

    return (
        <div>
            <form className={classes.root} noValidate autoComplete="off">
                <TextField 
                    id="height" 
                    type="number" 
                    value={height} 
                    label={metric === 'metric' ? "Height (cm)" : "Height (feet)"}
                    InputProps={{inputProps: {min: 1}}}
                    onChange={handleHeightChange}
                    variant="outlined"
                />
                {metric === 'standard' && (
                    <TextField 
                        id="inches" 
                        type="number" 
                        value={inches} 
                        label="Inches"
                        onChange={handleInchesChange}
                        variant="outlined"
                    />
                )}
                <TextField 
                    id="weight" 
                    type="number" 
                    value={weight} 
                    label={metric === 'metric' ? "Weight (kg)" : "Weight (lbs)"}
                    onChange={handleWeightChange}
                    variant="outlined"
                />
            </form>
            <FormControl component="fieldset">
                <RadioGroup
                    aria-label="metric"
                    name="metric"
                    value={metric}
                    onChange={handleMetricChange}
                >
                    <FormControlLabel value="standard" control={<Radio />} label="Standard" />
                    <FormControlLabel value="metric" control={<Radio />} label="Metric" />
                </RadioGroup>
            </FormControl>
            <div className={classes.stepCol}>
                <Button onClick={calculateBMI} variant="contained" color="primary">
                    Calculate BMI
                </Button>
            </div>
        </div>
    );
}

export default BMIForm;
